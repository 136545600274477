import axios from 'axios'
import router from '../router'

import {availableHousingPriceOptions} from '../data/availableHousingPriceOptions'
import {availableHousingPriceSquareFootOptions} from '../data/availableHousingPriceSquareFootOptions'
import {availableHousingInventoryOptions} from '../data/availableHousingInventoryOptions';
import {availableHousingNetChangeOptions} from '../data/availableHousingNetChangeOptions'
import {availableZeroTo100Options} from '../data/availableZeroTo100Options'
import {availableDiversityOptions} from '../data/availableDiversityOptions'
import {availablePctChangeOptions} from '../data/availablePctChangeOptions'
import {availablePctOptions} from '../data/availablePctOptions'
import {availableAvgIncomeOptions} from '../data/availableAvgIncomeOptions'
import {availablePopulationDensityOptions} from '../data/availablePopulationDensityOptions'
import {availablePopulationOptions} from '../data/availablePopulationOptions'
import {availableMedianAgeOptions} from '../data/availableMedianAgeOptions';
import {hotHousingOrderByOptions} from '../data/hotHousingOrderByOptions';
import {placesToLiveOrderByOptions} from '../data/placesToLiveOrderByOptions';
import {housingStatOrderByOptions} from '../data/housingStatOrderByOptions';
import {distanceToLargeAirportOptions} from '../data/distanceToLargeAirportOptions';
import {priceDeltaLast12MonthOptions} from '../data/priceDeltaLast12MonthOptions';

const getDefaultState = () => {
  return {
    latestHousingDataMonthYear: 'Oct 2023',
    brownZipCodes: [],
    purpleZipCodes: [],
    blueZipCodes: [],
    orangeZipCodes: [],
    haveZipsToCompare: false,
    schoolSearchType: 'place',
    schoolSearchReturnType: 'zip',
    schoolFetchType: 'top-rated-schools',
    schoolSearchPlaceType: 'state',
    mostImprovedSearchPlace: '',
    mostImprovedSearchRadius: 5,
    improvedSchoolsResultNumSchoolsInState: 0,
    improvedSchoolsResultNumZipsInState: 0,
    mostImprovedSearchSchoolLevel: 'all',
    mapZoom: 11,
    mapCenter: null,
    mapExtent: null,
    mapFilters: null,
    requestingUsersLocation: false,
    improvedSchoolsResultData: [],
    lastMapLoadTime: 0,
    searchType: 'places_to_live',
    schoolLevelCheckboxVals: ['High','Middle','Elementary'], //High,Middle,Elementary, 1 = enabled, 0 = disabled
    compareZipsLabel: 'Compare 0 of 4 Places',
    mapSearchArea: 'state',
    selectedUserPageView: 'saved-places',
    zipCodeInputValue: '',
    selectedHousingExpertId: '',
    showSignUpModal: false,
    userWatchingThisZip: false,
    searchReturnType: 'zip',
    similarSearchFill: 'limited', //Can be limited or all.
    signUpModalForm: 'register',
    showSimilarPlaceModal: false,
    fetchingSimilarPlaceDataAttributes: false,
    mapSearchReady: false,
    mapPageZips: [],
    mapDataReady: false,
    mapSelectedZip: '',
    distanceToLargeAirportOptions: distanceToLargeAirportOptions.options,
    priceDeltaLast12MonthOptions: priceDeltaLast12MonthOptions.options,
    housingStatOrderByOptions: housingStatOrderByOptions.options,
    placesToLiveOrderByOptions: placesToLiveOrderByOptions.options,
    hotHousingOrderByOptions: hotHousingOrderByOptions.options,
    housingPriceOptions: availableHousingPriceOptions.options,
    housingInventoryOptions: availableHousingInventoryOptions.options,
    housingPriceSquareFootOptions: availableHousingPriceSquareFootOptions.options,
    housingNetChangeOptions: availableHousingNetChangeOptions.options,
    generalZeroTo100Options: availableZeroTo100Options.options,
    diversityOptions: availableDiversityOptions.options,
    pctChangeOptions: availablePctChangeOptions.options,
    pctOptions: availablePctOptions.options,
    avgIncomeOptions: availableAvgIncomeOptions.options,
    popDensityOptions: availablePopulationDensityOptions.options,
    populationOptions: availablePopulationOptions.options,
    medianAgeOptions: availableMedianAgeOptions.options,
    zipsToCompare: [],
    housingPriceSliderVal: [0,500000],
    minInventorySliderVal: 1,
    interestRateSliderVal: 5.25,
    downPaymentSliderVal: 50000,
    schoolScoreSliderVal: [0,100],
    mapFilters: [
      {filter: 'schoolscorerange', value: '0-100'},
      {filter: 'homeownerpctrange', value: '0-100'},
      {filter: 'renterpctrange', value: '0-100'},
      {filter: 'medianagerange', value: '0-100'},
      {filter: 'popdensityrange', value: '0-200000'},
      {filter: 'minhomeprice', value: 0},
      {filter: 'maxhomeprice', value: 25000000},
    ],
    searchFilters: [
      {filter: 'median_listing_price', value: 'any', options: availableHousingPriceOptions},
      {filter: 'median_listing_price_per_square_foot', value: 'any', options: availableHousingPriceSquareFootOptions},
      {filter: 'housing_inventory', value: 'any', options: availableHousingInventoryOptions},
      {filter: 'distance_to_large_airport', value: 'any', options: distanceToLargeAirportOptions},
      {filter: 'median_listing_12m_peak_change', value: 'any', options: priceDeltaLast12MonthOptions},

      {filter: 'overall_score', value: 'any', options: availableZeroTo100Options},
      {filter: 'diversity_score', value: 'any', options: availableDiversityOptions},
      {filter: 'school_avg_pctprof_2yr_change', value: 'any', options: availablePctChangeOptions},

      {filter: 'population', value: 'any', options: availablePopulationOptions},
      {filter: 'population_density', value: 'any', options: availablePopulationDensityOptions},
      {filter: 'percent_asian', value: 'any', options: availablePctOptions},
      {filter: 'percent_asian_indian', value: 'any', options: availablePctOptions},
      {filter: 'percent_black', value: 'any', options: availablePctOptions},
      {filter: 'percent_white', value: 'any', options: availablePctOptions},

      {filter: 'avg_agi_per_return', value: 'any', options: availableAvgIncomeOptions},
      {filter: 'pct_returns_50k_100k', value: 'any', options: availablePctOptions},
      {filter: 'pct_returns_100k_plus', value: 'any', options: availablePctOptions},
      {filter: 'pct_returns_200k_plus', value: 'any', options: availablePctOptions},
      {filter: 'median_age', value: 'any', options: availableMedianAgeOptions},
    ],

    zipHousingBarChartInputValue: 'days_on_market',
    zipsCompareBarChartInputValue: 'days_on_market',
    hoveredMapZip: '',
    mapRankingInputVal: 'most_improved_schools',
    schoolScoreRange: [0,100],
    minSchoolScoreFilterValue: 0,
    medianAgeSurveyValue: 'none',
    schoolRankingSurveyValue: 'none',
    housingPricesSurveyValue: 'none',
    incomeLevelSurveyValue: 'none',
    popDensitySurveyValue: 'none',
    searchAreaSurveyInput: 'all-states',
    changingPlacesOrderBy: 'pctChange',
    changePlacesFromColName: '2018 Avg Median Price',
    changePlacesToColName: '2021 Avg Median Price',
    surveyPlaceValue: '',
    recentPlaceChange: false,
    placeInputType: '',
    surveySearchString: '',
    surveyResultsReturned: false,
    placeProximityRadiusSurveyValue: 5,
    selectedStatesSurveyValue: [],
    schoolsFromSearch: [],
    surveyZips: [],
    changingPlacesZips: [],
    schoolDistrict: {},
    resultsPrioritySurveyValue: 'population',
    userLocationLat: '',
    userLocationLng: '',
    serverErrorMsg: '',
    listView: false,
    selectedStateAbbr: 'MA',
    selectedAreaSearch: 'proximity',
    minHousingPriceFilter: 250000,
    maxHousingPriceFilter: 'none',
    studentProficiencyFilterValue: 'show-all',
    housingInventoryCompareDate: '2018',
    housingMonth: '4',
    housingYear: '2024',
    schoolYear: 2021,
    housingDateInput: '2024-4', //
    housingInventoryDateLabel: 'Apr 2024',
    housingInventoryCompareLabel: '2018',
    housingHotnessMetric: 'days_on_market',
    pageIsLoading: false,
    sendingFeedbackEmail: false,
    emailSuccessfullySent: false,
    canSubmitForm: false,
    submitIdeaFooterLabel: 'Thanks for helping us out!',
    showModal: false,
    searchingAllStates: false,
    stateFilterAdjusted: false,
    request: false,
    relativeMedianHomePriceForZip: 0,
    housingTrendFilter: 0,
    distanceFromZip: '02113',
    schoolLevel: 'All',
    greatSchoolRanking: 'top-30',
    selectionAreaSummaryStr: '',
    distanceFromRadius: 25,
    searchOrderByDir: 'asc',
    searchOrderBy: 'overall_score',
    housingInventorySearchOrderBy: 'net_change',
    housingHotnessSearchOrderBy: 'days_on_market',
    schoolSearchOrderBy: 'state_combined_pct',
    affordableGreatSchoolSearchOrderBy: 'avg_median_listing_price',
    avgReturnAmountFilter: "none",
    populationFilter: "none",
    populationDensityFilter: "none",
    medianAgeFilter: "none",
    submitIdeaFormName: "",
    submitIdeaFormFeedback: "",
    loanProgramInput: 30,
    propertyTaxRateSliderVal: 1.2,
    filteredStates: [],
    filteredGrades: [],
    searchFiltersApplied: [],
    zips: [],
    inventoryZips: [],
    hotHousingZips: [],
    risingSchools: [],
    affordableGreatSchoolZips: [],
    stateSchoolStats: [],
    school: '',
    zipCode: '',
    selectedState: '',
    selectedPlaceLat: '42.0667652',
    selectedPlaceLng: '-71.3281114',
    housingDateOptions: [],
    percentReturnFilter: [
      {agicategory: '0-50', value: 'none'},
      {agicategory: '50-100', value: 'none'},
      {agicategory: '100-200', value: 'none'},
      {agicategory: '200-plus', value: 'none'}
    ],
    schoolImpactFilter: [
      {level: 'High', value: 'none'},
      {level: 'Elementary', value: 'none'},
      {level: 'Middle', value: 'none'}
    ],
    achievementFilter: [
      {level: 'High', value: 'none'},
      {level: 'Elementary', value: 'none'},
      {level: 'Middle', value: 'none'}
    ],
    proficiencyFilter: [
      {level: 'High', value: 'none'},
      {level: 'Elementary', value: 'none'},
      {level: 'Middle', value: 'none'}
    ],
    proficiencyTrendFilter: [
      {level: 'High', value: 'none'},
      {level: 'Elementary', value: 'none'},
      {level: 'Middle', value: 'none'}
    ],
  }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    getters:{
        achievementFilter (state){
            return state.achievementFilter
        }
    },
    mutations: {

        UPDATE_PURPLE_ZIP_CODES(state, value) {
          state.purpleZipCodes = value;
        },
        UPDATE_BLUE_ZIP_CODES(state, value) {
          state.blueZipCodes = value;
        },
        UPDATE_ORANGE_ZIP_CODES(state, value) {
          state.orangeZipCodes = value;
        },
        UPDATE_BROWN_ZIP_CODES(state, value) {
          state.brownZipCodes = value;
        },
        UPDATE_LOAN_PROGRAM_INPUT(state, value) {
          state.loanProgramInput = value;
        },
        UPDATE_SCHOOL_SEARCH_PLACE_TYPE(state, value) {
          state.schoolSearchPlaceType = value;
        },
        UPDATE_SCHOOL_FETCH_TYPE(state, value) {
          state.schoolFetchType = value;
        },
        IMPROVED_SCHOOLS_RESULT_DATA(state,value) {
          state.improvedSchoolsResultData = value;
        },
        IMPROVED_SCHOOLS_RESULT_SCHOOLS_IN_STATE(state, value) {
          state.improvedSchoolsResultNumSchoolsInState = value;
        },
        IMPROVED_SCHOOLS_RESULT_ZIPS_IN_STATE(state, value) {
          state.improvedSchoolsResultNumZipsInState = value;
        },
        UPDATE_MOST_IMPROVED_SEARCH_SCHOOL_LEVEL(state, value) {
          state.mostImprovedSearchSchoolLevel = value;
        },
        UPDATE_MOST_IMPROVED_SEARCH_RADIUS(state, value) {
          state.mostImprovedSearchRadius = value;
        },
        UPDATE_SCHOOL_SEARCH_RETURN_TYPE(state, value) {
          state.schoolSearchReturnType = value;
        },
        UPDATE_SCHOOL_SEARCH_TYPE(state, value) {
          state.schoolSearchType = value;
        },
        UPDATE_MOST_IMPROVED_SEARCH_TYPE(state, value) {
          state.mostImprovedSearchType = value;
        },
        UPDATE_MOST_IMPROVED_SEARCH_PLACE(state, value) {
          state.mostImprovedSearchPlace = value;
        },
        UPDATE_RECENT_PLACE_CHANGE(state, value) {
          state.recentPlaceChange = value;
        },
        UPDATE_LAST_MAP_LOAD_TIME(state, value) {
          state.lastMapLoadTime = value;
        },
        UPDATE_SELECTED_HOUSING_EXPERT_ID(state, value) {
          state.selectedHousingExpertId = value;
        },
        UPDATE_SELECTED_USER_PAGE_VIEW(state, value) {
          state.selectedUserPageView = value;
        },
        UPDATE_ZIP_CODE_INPUT_VALUE(state,value) {
          state.zipCodeInputValue = value;
        },
        SET_SIGN_UP_MODAL_FORM(state, value) {
          state.signUpModalForm = value;
        },
        UPDATE_PROPERTY_TAX_RATE_SLIDER_VAL(state, value) {
          state.propertyTaxRateSliderVal = value;
        },
        UPDATE_HOUSING_PRICE_SLIDER_VAL(state, value) {
          state.housingPriceSliderVal = value;
        },
        UPDATE_MIN_INVENTORY_SLIDER_VAL(state, value) {
          state.minInventorySliderVal = value;
        },
        UPDATE_DOWN_PAYMENT_SLIDER_VAL(state, value) {
          state.downPaymentSliderVal = value;
        },
        UPDATE_INTEREST_RATE_SLIDER_VAL(state, value) {
          state.interestRateSliderVal = value;
        },
        UPDATE_SCHOOL_SCORE_SLIDER_VAL(state, value) {
          state.schoolScoreSliderVal = value;
        },
        UPDATE_SCHOOL_LEVEL_CHECKBOX_VALS(state, value) {
          state.schoolLevelCheckboxVals = value;
        },
        UPDATE_ZIP_HOUSING_BAR_CHART_INPUT_VALUE(state, value) {
          state.zipHousingBarChartInputValue = value;
        },
        UPDATE_ZIPS_COMPARE_BAR_CHART_INPUT_VALUE(state, value) {
          state.zipsCompareBarChartInputValue = value;
        },
        UPDATE_HAVE_ZIPS_TO_COMPARE(state, value) {
          state.haveZipsToCompare = value;
        },
        UPDATE_COMPARE_ZIPS_LABEL(state, value) {
          state.compareZipsLabel = value;
        },
        RESET_ZIPS_TO_COMPARE(state) {
          state.zipsToCompare = [];
        },
        ADD_ZIP_TO_ZIPS_TO_COMPARE(state, value) {
          let zipsToCompare = state.zipsToCompare;

          if(!zipsToCompare.includes(value)) {
            zipsToCompare.push(value);
          }

          state.zipsToCompare = zipsToCompare;
        },
        UPDATE_MAP_CENTER(state, value) {
          state.mapCenter = value;
        },
        UPDATE_MAP_EXTENT(state, value) {
          state.mapExtent = value;
        },
        UPDATE_MAP_ZOOM(state, value) {
          state.mapZoom = value;
        },
        REMOVE_ZIP_FROM_ZIPS_TO_COMPARE(state,value) {
          let zipsToCompare = state.zipsToCompare;
          state.zipsToCompare = zipsToCompare.filter(item => item !== value)
        },
        UPDATE_MAP_ZIP_HOVERED(state, value) {
          state.hoveredMapZip = value;
        },
        UPDATE_USER_WATCHING_THIS_ZIP(state, value) {
          state.userWatchingThisZip = value;
        },
        UPDATE_SHOW_SIGN_UP_MODAL(state, value) {
          state.showSignUpModal = value;
        },
        UPDATE_MAP_PAGE_ZIPS(state, value) {
          state.mapPageZips = value;
        },
        UPDATE_SHOW_SIMILAR_PLACE_MODAL(state, value) {
          state.showSimilarPlaceModal = value;
        },
        UPDATE_MAP_DATA_READY(state, value) {
          state.mapDataReady = value;
        },
        UPDATE_SIMILAR_SEARCH_FILL(state, value) {
          state.similarSearchFill = value
        },

        RESET_MAP_FILTERS(state) {
          //Probably poor practice but I only want this to reset the filters.
          let defaultMapFilterState = getDefaultState();

          state.mapFilters = defaultMapFilterState.mapFilters;

        },
        UPDATE_MAP_FILTERS(state,obj) {

          const item = state.mapFilters.find(item => item.filter == obj.filterName);

          if(item) {
            Object.assign(item, {filter: obj.filterName, value: obj.value});
          }
          
        },
        UPDATE_MAP_SELECTED_ZIP(state, value) {
          state.mapSelectedZip = value;
        },
        UPDATE_MIN_SCHOOL_SCORE_FILTER_VAL(state, value) {
          state.minSchoolScoreFilterValue = value;
        },
        UPDATE_FETCHING_DATA_ATTRIBUTES(state, value) {
          state.fetchingSimilarPlaceDataAttributes = value;
        },
        UPDATE_MAP_RANKING_INPUT_VAL(state, value) {
          state.mapRankingInputVal = value;
        },
        UPDATE_SELECTED_PLACE_LAT(state, value) {
          state.selectedPlaceLat = value;
        },
        UPDATE_SELECTED_PLACE_LNG(state, value) {
          state.selectedPlaceLng = value;
        },
        UPDATE_SCHOOL_SCORE_RANGE(state,value) {
          state.schoolScoreRange = value;
        },
        UPDATE_SURVEY_ZIPS(state, value) {
          state.surveyZips = value;
        },
        SET_SURVEY_RESULTS_RETURNED(state, value) {
          state.surveyResultsReturned = value;
        },
        UPDATE_SCHOOLS_FROM_SEARCH(state, value) {
          state.schoolsFromSearch = value;
        },
        UPDATE_SURVEY_SEARCH_STRING(state, value) {
          state.surveySearchString = value
        },
        UPDATE_SURVEY_PLACE_VALUE (state, value) {
          state.surveyPlaceValue = value;
        },
        UPDATE_PLACE_INPUT_TYPE (state, value) {
          state.placeInputType = value;
        },
        UPDATE_RESULTS_PRIORITY_SURVEY_VALUE (state, value) {
          state.resultsPrioritySurveyValue = value;
        },
        UPDATE_STATE_ABBR_INPUT (state, value) {
          state.selectedStateAbbr = value;
        },
        UPDATE_MEDIAN_AGE_SURVEY_VALUE (state, value) {
          state.medianAgeSurveyValue = value
        },
        UPDATE_SCHOOL_RANKING_SURVEY_VALUE (state, value) {
          state.schoolRankingSurveyValue = value
        },
        UPDATE_HOUSING_PRICES_SURVEY_VALUE (state, value) {
          state.housingPricesSurveyValue = value;
        },
        UPDATE_INCOME_LEVEL_SURVEY_VALUE (state, value) {
          state.incomeLevelSurveyValue = value;
        },
        UPDATE_POP_DENSITY_SURVEY_VALUE (state, value) {
          state.popDensitySurveyValue = value;
        },
        UPDATE_SEARCH_AREA_INPUT_VALUE (state, value) {
          state.searchAreaSurveyInput = value;
        },
        UPDATE_SURVEY_PROXIMITY_VALUE (state, value) {
          state.proximityPlaceSurveyValue = value;
        },
        UPDATE_PLACE_PROXIMITY_RADIUS_SURVEY_VALUE (state, value) {
          state.placeProximityRadiusSurveyValue = value;
        },
        UPDATE_SELECTED_STATES_SURVEY_VALUE (state, value) {
          state.selectedStatesSurveyValue = value;
        },
        UPDATE_LIST_VIEW (state,value) {
          state.listView = value;
        },
        UPDATE_SELECTION_AREA_STRING (state, value) {
          state.selectionAreaSummaryStr = value;
        },
        UPDATE_SCHOOL_YEAR (state, value) {
          state.schoolYear = value;
        },
        UPDATE_HOUSING_DATE_INPUT (state, value) {
          state.housingDateInput = value;
        },
        UPDATE_SENDING_FEEDBACK_EMAIL (state, value) {
          state.sendingFeedbackEmail = value;
        },
        UPDATE_EMAIL_SUCCESS (state, value) {
          state.emailSuccessfullySent = value;
        },
        UPDATE_CAN_SUBMIT_FORM (state, value) {
          state.canSubmitForm = value;
        },
        UPDATE_SUBMIT_IDEA_FOOTER_LABEL (state, value) {
          state.submitIdeaFooterLabel = value
        },
        SET_SHOW_MODAL (state, value) {
          state.showModal = value;
        },
        UPDATE_SCHOOL_DISTRICT (state, value) {
          state.schoolDistrict = value;
        },
        UPDATE_SUBMIT_IDEA_FORM_NAME (state, value) {
          state.submitIdeaFormName = value;
        },
        UPDATE_SUBMIT_IDEA_FORM_FEEDBACK (state, value) {
          state.submitIdeaFormFeedback = value;
        },
        UPDATE_GREAT_SCHOOL_RANKING (state,value) {
          state.greatSchoolRanking = value;
        },
        UPDATE_AFFORDABLE_GREAT_SCHOOL_ZIPS (state, value) {
          state.affordableGreatSchoolZips = value
        },
        UPDATE_AFFORDABLE_GREAT_SCHOOL_ORDER_BY (state, value) {
          state.affordableGreatSchoolSearchOrderBy = value;
        },
        UPDATE_SCHOOL_LEVEL (state, value) {
          state.schoolLevel = value;
        },
        UPDATE_USER_LOCATION_LAT (state, value) {
          state.userLocationLat = value;
        },
        UPDATE_RISING_ZIPS(state, value) {
          state.risingSchools = value;
        },
        UPDATE_SCHOOL_SEARCH_ORDER_BY (state, value) {
          state.schoolSearchOrderBy = value;
        },
        UPDATE_USER_LOCATION_LNG (state, value) {
          state.userLocationLng = value;
        },
        UPDATE_HOUSING_INVENTORY_COMPARE_DATE (state, value) {
          state.housingInventoryCompareDate = value
        },
        UPDATE_HOUSING_HOTNESS_SEARCH_ORDER_BY (state, value) {
          state.housingHotnessSearchOrderBy = value
        },
        UPDATE_HOUSING_HOTNESS_METRIC (state, value) {
          state.housingInventoryCompare = value
        },
        UPDATE_HOUSING_INVENTORY_DATE_LABEL (state, value) {
          state.housingInventoryDateLabel = value;
        },
        UPDATE_HOUSING_INVENTORY_COMPARE_LABEL (state, value) {
          state.housingInventoryCompareLabel = value;
        },
        UPDATE_STATE(state,value) {
          state.selectedState = value
        },
        UPDATE_SELECTED_AREA_SEARCH (state, value) {
          state.selectedAreaSearch = value;
        },
        UPDATE_REQUESTING_USERS_LOCATION(state, value) {
          state.requestingUsersLocation = value;
        },
        UPDATE_STUDENT_ACHIEVEMENT (state, obj) {
          const item = state.achievementFilter.find(item => item.level === obj.level);
          Object.assign(item, {level: obj.level, value: obj.value});
        },
        UPDATE_STUDENT_PROFICIENCY (state, obj) {
          const item = state.proficiencyFilter.find(item => item.level === obj.level);
          Object.assign(item, {level: obj.level, value: obj.value});
        },
        UPDATE_STUDENT_PROFICIENCY_TRENDS (state, obj) {
          const item = state.proficiencyTrendFilter.find(item => item.level === obj.level);
          Object.assign(item, {level: obj.level, value: obj.value});
        },
        UPDATE_SCHOOL_IMPACT (state, obj) {
          const item = state.schoolImpactFilter.find(item => item.level === obj.level);
          Object.assign(item, {level: obj.level, value: obj.value});
        },
        UPDATE_SEARCH_FILTERS (state, obj) {
          const item = state.searchFilters.find(item => item.filter === obj.filter);
          Object.assign(item, {filter: obj.filter, value: obj.value});
        },
        UPDATE_PCT_RETURN_FILTER (state, obj) {
          const item = state.percentReturnFilter.find(item => item.agicategory === obj.agicategory);
          Object.assign(item, {agicategory: obj.agicategory, value: obj.value});
        },
        UPDATE_MIN_HOUSING_PRICE_FILTER (state, value) {
          state.minHousingPriceFilter = value;
        },
        UPDATE_MAP_SEARCH_AREA(state, value) {
          state.mapSearchArea = value;
        },
        UPDATE_SEARCH_RETURN_TYPE(state, value) {
          state.searchReturnType = value;
        },
        UPDATE_MAP_SEARCH_READY(state, value) {
          state.mapSearchReady = value;
        },
        UPDATE_HOUSING_MONTH (state, value) {
          state.housingMonth = value;
        },
        UPDATE_STATE_SCHOOL_STATS (state, value) {
          state.stateSchoolStats = value;
        },
        UPDATE_HOUSING_YEAR (state, value) {
          state.housingYear = value;
        },
        UPDATE_MAX_HOUSING_PRICE_FILTER (state, value) {
          state.maxHousingPriceFilter = value;
        },
        UPDATE_HOUSING_TREND_FILTER (state, value) {
          state.housingTrendFilter = value;
        },
        UPDATE_STATE_FILTERS (state, value) {
          state.filteredStates = value;
        },
        UPDATE_GRADE_FILTERS (state, value) {
          state.filteredGrades = value;
        },
        UPDATE_STUDENT_PROFICIENCY_FILTER_VALUE (state, value) {
          state.studentProficiencyFilterValue = value;
        },
        UPDATE_STATE_ADJUSTED (state, value) {
          state.stateFilterAdjusted = value
        },
        UPDATE_ZIPS (state, value) {
          state.zips = value;
        },
        UPDATE_INVENTORY_ZIPS (state, value) {
          state.inventoryZips = value;
        },
        UPDATE_CHANGING_PLACES_ZIPS (state, value) {
          state.changingPlacesZips = value;
        },
        SET_PAGE_IS_LOADING (state, value) {
          state.pageIsLoading = value;
        },
        SET_REQUEST (state, value) {
          state.request = value;
        },
        UPDATE_HOT_HOUSING_ZIPS (state, value) {
          state.hotHousingZips = value;
        },
        UPDATE_POPULATION_DENSITY_FILTER (state, value) {
          state.populationDensityFilter = value;
        },
        UPDATE_MEDIAN_AGE_INPUT_FILTER (state, value) {
          state.medianAgeFilter = value;
        },
        UPDATE_POPULATION_INPUT_FILTER (state, value) {
          state.populationFilter = value;
        },
        UPDATE_HOUSING_INVENTORY_SEARCH_ORDER_BY (state, value) {
          state.housingInventorySearchOrderBy = value;
        },
        UPDATE_AVG_RETURN_INPUT_FILTER (state, value) {
          state.avgReturnAmountFilter = value;
        },
        UPDATE_APPLIED_SEARCH_FILTERS (state, value) {
          state.searchFiltersApplied = value;
        },
        UPDATE_SCHOOL (state, value) {
          state.school = value;
        },
        UPDATE_DISTANCE_FROM_RADIUS (state, value) {
          state.distanceFromRadius = value
        },
        UPDATE_DISTANCE_FROM_ZIP (state, value) {
          state.distanceFromZip = value
        },
        UPDATE_SEARCH_ORDER_DIR (state, value) {
          state.searchOrderByDir = value;
        },
        UPDATE_ZIP_CODE (state, value) {
          state.zipCode = value
        },
        UPDATE_SEARCH_ORDER_BY (state, value) {
          state.searchOrderBy = value;
        },
        UPDATE_SERVER_ERROR_MSG (state, value) {
          state.serverErrorMsg = value;
        },
        UPDATE_SEARCHING_ALL_STATES (state, value) {
          state.searchingAllStates = value;
        },
        UPDATE_RELATIVE_MEDIAN_HOME_PRICE_FOR_ZIP (state, value) {
          state.relativeMedianHomePriceForZip = value;
        },
        RESET_SEARCH_FILTERS(state) {
          //Probably poor practice but I only want this to reset the filters.
          let defaultSearchFilters = getDefaultState().searchFilters;
          state.searchFilters = defaultSearchFilters;

          //state.popDensityOptions = defaultSearchFilters.popDensityOptions;
          Object.assign(state, getDefaultState());
        },
        UPDATE_HOUSING_DATE_OPTIONS (state, value) {
          
          state.housingDateOptions = value
        }
    },
    actions:{
      updateCalc({commit}) {
        //Future use.
      },

      showSignUpModal({commit}) {
        commit('SET_SIGN_UP_MODAL_FORM', 'register');
        commit('UPDATE_SHOW_SIGN_UP_MODAL', true);
      },
      showLoginModal({commit}) {
        commit('SET_SIGN_UP_MODAL_FORM', 'login');
        commit('UPDATE_SHOW_SIGN_UP_MODAL', true);
      },
      setSimilarSearchFill({commit}, value) {
        commit('UPDATE_SIMILAR_SEARCH_FILL', value);
      },

      updateUsersGeoLocationAndFetchMapZips({commit, dispatch, state}) {

        commit('UPDATE_REQUESTING_USERS_LOCATION', true);

        navigator.geolocation.getCurrentPosition(
           position => {

            let usersLat = position.coords.latitude;
            let usersLon = position.coords.longitude;

            commit('UPDATE_USER_LOCATION_LAT', usersLat);
            commit('UPDATE_USER_LOCATION_LNG', usersLon);

            dispatch('updateSelectedPlaceFromUserLatLng');
           },
           error => {
             console.log(error.message);
             commit('UPDATE_REQUESTING_USERS_LOCATION', false);
           },
        );
      },

      updateUsersGeoLocation({ commit, dispatch, state}) {
        navigator.geolocation.getCurrentPosition(
           position => {

             commit('UPDATE_USER_LOCATION_LAT', position.coords.latitude);
             commit('UPDATE_USER_LOCATION_LNG', position.coords.longitude);
             //dispatch('addUserLocationToStateFilters');
           },
           error => {
             console.log(error.message);
           },
        );
      },

      handleNewSearchClick({ commit, dispatch, state}) {
        commit('RESET_SEARCH_FILTERS');
        commit("UPDATE_ZIPS", []);
      },
      handleNearbyMapSearch({commit, state}) {
        //We need these values.

        if(state.surveyPlaceValue && state.placeInputType) {
          axios.get('/api/zips/similarPlaces/' + state.placeInputType + '/' + state.surveyPlaceValue)
            .then((response) => {
              
              console.log(response);


            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
          });
        }

      },
      fetchAvailableHousingDateOptions({commit, state}) {
        axios.get('/api/housing/availableHousingPeriods')
          .then((response) => {
            if(response.data && response.data.housing_periods)
            commit('UPDATE_HOUSING_DATE_OPTIONS', response.data.housing_periods);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
        });
      },

      setMapExtent({commit, state}) {
        commit('UPDATE_MAP_EXTENT', null);
      },

      fetchMapZips({commit, state}) {

        commit('UPDATE_MAP_DATA_READY', false);

        if(state.mapExtent) {

          axios.get('/api/zips/getFromMapExtent', {
            params: {
              zoom: parseInt(state.mapZoom),
              center: state.mapCenter,
              extent: state.mapExtent,
              filters: state.mapFilters
            }
          })
          .then(function (response) {

            commit('UPDATE_MAP_PAGE_ZIPS', response.data);

          }.bind(this)).catch(function (error) {
            console.log(error);
          });
        }
        

      },

      updateFilterFromSimilarPlaceAttr({commit, state}, dataAttr) {
        let filterName = dataAttr[0];
        let dataAttrValue = dataAttr[1];
        let newFilterVal = null;

        const filter = state.searchFilters.find(item => item.filter === filterName);

        if(filter) {

          newFilterVal = filter.options.getOptionFromDataAttribute(dataAttrValue);

          if(newFilterVal) {
            commit('UPDATE_SEARCH_FILTERS', {value: newFilterVal.value, filter: filterName})
          }
          
        } else {
          console.log("Unable to find filter with name: " + filterName);
        }
        
      },
      resetZipsToCompare({commit, dispatch}) {
        commit('RESET_ZIPS_TO_COMPARE');
        commit('UPDATE_COMPARE_ZIPS_LABEL', 'Compare 0 of 4 Places'); //4 is an artificial limit
        commit('UPDATE_HAVE_ZIPS_TO_COMPARE', false);

      },
      handleSimilarPlaceMapSearch({commit, dispatch, state}) {

        commit('UPDATE_FETCHING_DATA_ATTRIBUTES', true);
        commit("UPDATE_SERVER_ERROR_MSG", '');

        if(state.surveyPlaceValue && state.placeInputType) {
          axios.get('/api/zips/dataAttributes/' + state.placeInputType + '/' + state.surveyPlaceValue + '/' + state.similarSearchFill)
            .then((response) => {

              //Clear the current selections.
              commit('RESET_SEARCH_FILTERS');
              
              let similarPlaceAttributes = Object.entries(response.data[0]);

              similarPlaceAttributes.forEach((similarPlaceAttr) => {
                dispatch('updateFilterFromSimilarPlaceAttr', similarPlaceAttr);
              });

              commit('UPDATE_FETCHING_DATA_ATTRIBUTES', false);

              commit('UPDATE_SHOW_SIMILAR_PLACE_MODAL', false);

              dispatch('performSearch');

            })
            .catch(function (error) {
              // handle error
              console.log(error);
              commit('UPDATE_FETCHING_DATA_ATTRIBUTES', false);
            })
            .then(function () {
              // always executed
          });
        } else {
          commit("UPDATE_SERVER_ERROR_MSG", 'Please enter a valid town or zip code.');
          commit('UPDATE_FETCHING_DATA_ATTRIBUTES', false);
        }
      },
      setSearchReturnType({ commit}, value) {
        commit('UPDATE_SEARCH_RETURN_TYPE', value);
        //dispatch('addUserLocationToStateFilters');
      },
      setMapSearchArea({ commit}, value) {
        commit('UPDATE_MAP_SEARCH_AREA', value);
        //dispatch('addUserLocationToStateFilters');
      },
      closeSignUpModal({commit}) {
        commit('UPDATE_SHOW_SIGN_UP_MODAL', false);
      },
      closeSimilarPlaceModal({commit}) {
        commit('UPDATE_SHOW_SIMILAR_PLACE_MODAL', false);
      },
      handleFindSimilarPlacesClick({commit}) {
        commit('UPDATE_SHOW_SIMILAR_PLACE_MODAL', true);

        commit('UPDATE_FETCHING_DATA_ATTRIBUTES', false);

      },

      updateSelectedPlaceFromUserLatLng({ commit, dispatch, state }) {

        if(state.userLocationLat && state.userLocationLng) {
          axios.get('/api/state/latlng/' + state.userLocationLat + '/' + state.userLocationLng)
            .then((response) => {

              if(response.data && response.data.zip) {

                commit('UPDATE_SURVEY_PLACE_VALUE', response.data.zip);
                commit('UPDATE_PLACE_INPUT_TYPE', 'zip');
                commit('UPDATE_SELECTED_PLACE_LAT', response.data.lat);
                commit('UPDATE_SELECTED_PLACE_LNG', response.data.lng);

                dispatch('setMapExtent');

                dispatch('fetchMapZips');

              }

            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed

              commit('UPDATE_REQUESTING_USERS_LOCATION', false);
          });
        }
      },
      addUserLocationToStateFilters({ commit, state }) {

        if(state.userLocationLat && state.userLocationLng && !state.stateFilterAdjusted) {
          axios.get('/api/state/latlng/' + state.userLocationLat + '/' + state.userLocationLng)
            .then((response) => {
              
              //If this is a valid entry...  Not sure how to do this.
              let availableStates = [
               'AL','AK','AZ','AR','CA','CO','CT','DE','DC','FL','GA',
               'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA',
               'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
               'OH','OK','OR','PA','RI','SC','SD','TN','TX','UT',
               'VT','VI','VA','WA','WV','WI','WY'
              ];

              if(availableStates.includes(response.data.state_abbr)) {
                commit("UPDATE_STATE_FILTERS", [response.data.state_abbr]);

                commit("UPDATE_DISTANCE_FROM_ZIP", response.data.zip);
              }

            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
          });
        }
        
      },

      handleZipsToCompareChange({ commit, state }) {
        let zipsToCompare = state.zipsToCompare;

        commit("UPDATE_COMPARE_ZIPS_LABEL", "Compare " + zipsToCompare.length + " of 4 Places");

        // Do whatever makes sense now
        if (zipsToCompare && zipsToCompare.length > 1) {
          commit("UPDATE_HAVE_ZIPS_TO_COMPARE", true);
        } else {
          commit("UPDATE_HAVE_ZIPS_TO_COMPARE", false);
        }
      },

      fetchSurveyPlaces({ commit, state }) {

        commit("SET_PAGE_IS_LOADING", true);

        const request = axios.CancelToken.source();

        commit("SET_REQUEST", request);

        commit("UPDATE_SURVEY_ZIPS", []);

        commit("SET_SURVEY_RESULTS_RETURNED", false);

        commit("UPDATE_SERVER_ERROR_MSG", '');

        let housingPriceValues = state.housingPricesSurveyValue;

        let minHousingPrice = 'none';
        let maxHousingPrice = 'none';

        if(housingPriceValues && housingPriceValues !== 'none') {
          minHousingPrice = housingPriceValues.split('-')[0];

          maxHousingPrice = housingPriceValues.split('-')[1];
        }

        let schoolScoreRange = [0,100];

        if(state.schoolRankingSurveyValue == 'top-10') {
          schoolScoreRange = [70,100];
        }

        if(state.schoolRankingSurveyValue == 'top-50') {
          schoolScoreRange = [50,100];
        }

        if(state.schoolRankingSurveyValue == 'top-80') {
          schoolScoreRange = [20,100];
        }

        
        let surveySearchString = "";
        if(state.medianAgeSurveyValue !== "none") {
          surveySearchString += "Age: " + state.medianAgeSurveyValue;
        }

        if(state.schoolRankingSurveyValue !== "none") {
          surveySearchString += "Schools: " + state.schoolRankingSurveyValue;
        }

        if(housingPriceValues !== "none") {
          surveySearchString += "Housing: " + housingPriceValues;
        }

        if(state.incomeLevelSurveyValue !== "none") {
          surveySearchString += "Income: " + state.incomeLevelSurveyValue;
        }

        if(state.popDensitySurveyValue !== "none") {
          surveySearchString += "Population Density: " + state.popDensitySurveyValue;
        }

        if(state.searchAreaSurveyInput == "states") {
          surveySearchString += ". In " + state.selectedStatesSurveyValue.join(",");
        }

        if(state.searchAreaSurveyInput == "proximity") {
          surveySearchString += ". Within " + state.placeProximityRadiusSurveyValue + " miles of " + state.surveyPlaceValue;
        }

        if(state.searchAreaSurveyInput == "all-states") {
          surveySearchString += ". Across all States in the USA";
        }

        commit("UPDATE_SURVEY_SEARCH_STRING", surveySearchString);

        axios.get('/api/search/income_housing_schools', {
          cancelToken: request.token,
          params: {
            selectedAreaSearch: state.searchAreaSurveyInput,
            filteredStates: state.selectedStatesSurveyValue,
            placeValue: state.surveyPlaceValue,
            placeType: state.placeInputType,
            distanceFromRadius: state.placeProximityRadiusSurveyValue,
            minHousingPriceFilter: minHousingPrice,
            maxHousingPriceFilter: maxHousingPrice,
            avgReturnAmountFilter: state.incomeLevelSurveyValue,
            schoolScoreRange: schoolScoreRange,
            populationDensityFilter: state.popDensitySurveyValue,
            medianAgeFilter: state.medianAgeSurveyValue,
            orderBy: state.resultsPrioritySurveyValue,
            orderByDir: 'desc',          
          }
          })
          .then((response) => {
            commit("SET_PAGE_IS_LOADING", false);

            if(response.error) {
              commit("UPDATE_SERVER_ERROR_MSG", response.error);
            } else {
              commit("UPDATE_SURVEY_ZIPS", response.data);

              commit("SET_SURVEY_RESULTS_RETURNED", true);
            }

          })
          .catch(function (error) {
            // handle error
            commit("UPDATE_SERVER_ERROR_MSG", 'Sorry, something went wrong trying to fetch results.  Please try again in a few minutes.');
            commit("SET_PAGE_IS_LOADING", false);
          })
          .then(function () {
            // always executed
        });

      },
      fetchSchoolDistrictDetails({ commit }, value) {

        commit("SET_PAGE_IS_LOADING", true);

        axios.get('/api/schools/district/' + value)
          .then((response) => {
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_SCHOOL_DISTRICT", response.data);

          })
          .catch(function (error) {
            // handle error
            console.log(error);
            commit("SET_PAGE_IS_LOADING", false);
          })
          .then(function () {
            // always executed
        });
      },
      fetchStateDetails({ commit }, value) {

        commit("SET_PAGE_IS_LOADING", true);

        axios.get('/api/state/' + value)
          .then((response) => {
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_STATE", response.data);

          })
          .catch(function (error) {
            // handle error
            console.log(error);
            commit("SET_PAGE_IS_LOADING", false);
          })
          .then(function () {
            // always executed
        });
      },
      fetchEveryStateSchoolStatistics({ commit, state }) {

        commit("SET_PAGE_IS_LOADING", true);

        axios.get('/api/schools/statistics/' + state.schoolYear)
          .then((response) => {
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_STATE_SCHOOL_STATS", response.data);

          })
          .catch(function (error) {
            // handle error
            console.log(error);
            commit("SET_PAGE_IS_LOADING", false);
          })
          .then(function () {
            // always executed
        });
      },
      fetchZipCodeDetails({ commit }, value) {

        commit("SET_PAGE_IS_LOADING", true);

        axios.get('/api/zip/' + value)
          .then((response) => {
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_ZIP_CODE", response.data);

          })
          .catch(function (error) {
            // handle error
            console.log(error);
            commit("SET_PAGE_IS_LOADING", false);
          })
          .then(function () {
            // always executed
        });
      },
      submitIdea({commit, state}) {

        commit("UPDATE_SENDING_FEEDBACK_EMAIL", true);

        axios.post('/api/submitFeedbackForm', {
          name: state.submitIdeaFormName,
          feedback: state.submitIdeaFormFeedback,
        }).then((response) => {

          if(response.data.error) {
            
            console.log("Unable to send email...");

            commit("UPDATE_SUBMIT_IDEA_FOOTER_LABEL", "Sorry, feedback not sent.  Make sure both fields are filled out.");

          } else {
            
            //Reset the form
            commit("UPDATE_SUBMIT_IDEA_FORM_NAME", "");
            commit("UPDATE_SUBMIT_IDEA_FORM_FEEDBACK", "");

            commit("UPDATE_SUBMIT_IDEA_FOOTER_LABEL", "Feedback successfully sent!");

            commit("UPDATE_CAN_SUBMIT_FORM", false);

            commit("UPDATE_EMAIL_SUCCESS", true);

          }
            

        }).catch(function (error) {

          //commit("UPDATE_SENDING_FEEDBACK_EMAIL", false);

          console.log(error);
        }).then(function () {
          commit("UPDATE_SENDING_FEEDBACK_EMAIL", false);
        });
      },
      fetchNearbySchoolsByType({ commit, state }) {

        commit("SET_PAGE_IS_LOADING", true);

        axios.get('/api/schools/fetch_nearby', {
          params: {
            method: state.schoolFetchType,
            searchType: state.schoolSearchType,
            searchPlace: state.mostImprovedSearchPlace,
            searchPlaceType: state.schoolSearchPlaceType,
            searchReturnType: state.schoolSearchReturnType,
            searchSchoolLevel: state.mostImprovedSearchSchoolLevel,
            searchRadius: state.mostImprovedSearchRadius,
            minSchoolScoreFilterValue: state.minSchoolScoreFilterValue
            }
          })
          .then((response) => {
            commit("SET_PAGE_IS_LOADING", false);            
            if(Array.isArray(response.data.records) && response.data.records.length) {
              commit("IMPROVED_SCHOOLS_RESULT_DATA", response.data.records);

              if(response.data.hasOwnProperty('NumSchoolsInState')) {
                commit("IMPROVED_SCHOOLS_RESULT_SCHOOLS_IN_STATE", response.data.NumSchoolsInState);
              }

              if(response.data.hasOwnProperty('NumZipsInState')) {
                commit("IMPROVED_SCHOOLS_RESULT_ZIPS_IN_STATE", response.data.NumZipsInState);
              }
            } else {
              commit("IMPROVED_SCHOOLS_RESULT_DATA", []);
            }
            
          })
          .catch(function (error) {
            // handle error
            console.log(error);
            commit("SET_PAGE_IS_LOADING", false);
          })
          .then(function () {
            // always executed
        });
      },

      fetchSchoolDetails({ commit }, value) {

        commit("SET_PAGE_IS_LOADING", true);

        axios.get('/api/school/' + value)
          .then((response) => {
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_SCHOOL", response.data);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
            commit("SET_PAGE_IS_LOADING", false);
          })
          .then(function () {
            // always executed
        });
      },
      upstateStateFilters({ commit }, value) {
        commit("UPDATE_STATE_ADJUSTED", true);
        commit("UPDATE_STATE_FILTERS", value);
      },
      upstateGradeFilters({ commit }, value) {
        commit("UPDATE_GRADE_FILTERS", value);
      },
      updateSearchFilters({ commit, state }, obj) {

        let searchFiltersApplied = state.searchFiltersApplied;

        const item = state.searchFiltersApplied.find(item => item.key === obj.key);

        if(item) {
          if(obj.value == '') {
            //This is an attempt to remove the value from the array of filters.  This is done by setting the value to ''.
            searchFiltersApplied = state.searchFiltersApplied.filter(function (val) {
                return val.key !== obj.key;
            });
          } else {
            Object.assign(item, obj);
          }
          
        } else {
          searchFiltersApplied.push(obj);
        }

        commit("UPDATE_APPLIED_SEARCH_FILTERS", searchFiltersApplied);

      },

      cancelRequest({ commit, state}) {
        state.request.cancel("Cancelling Request");
        commit("SET_REQUEST", false);
      },
      updateSelectionAreaString({commit, state}) {
        let str = "";

        const listFormatter = new Intl.ListFormat('en', {
          style: 'long',
          type: 'conjunction'
        });

        if(state.selectedAreaSearch == "all-states") {
          str = 'in the USA';
        }

        if(state.selectedAreaSearch == "single-state" || state.selectedAreaSearch == "multi-states") {
          if(state.filteredStates.length > 0) {
            str = 'in ' + listFormatter.format(state.filteredStates);
          }
        }

        if(state.selectedAreaSearch == "geolocation") {
          if(state.distanceFromZip && state.distanceFromRadius) {
            str = 'within ' + state.distanceFromRadius + ' miles of ' + state.distanceFromZip
          }
        }

        commit('UPDATE_SELECTION_AREA_STRING', str);
      },



      performAffordableGreatSchoolSearch({ commit, state }) {

        commit("SET_PAGE_IS_LOADING", true);
        commit("UPDATE_SEARCHING_ALL_STATES", false);

        if(state.selectedAreaSearch == 'all-states') {
          commit("UPDATE_SEARCHING_ALL_STATES", true);
        }

        const request = axios.CancelToken.source();

        commit("SET_REQUEST", request);

        commit("UPDATE_AFFORDABLE_GREAT_SCHOOL_ZIPS", []);

        axios.get('/api/search', {
          cancelToken: request.token,
          params: {
            searchType: 'affordable_schools',
            searchOrderBy: state.searchOrderBy,
            searchOrderByDir: state.searchOrderByDir,
            searchReturnType: state.searchReturnType,
            searchArea: state.mapSearchArea,
            states: state.filteredStates,
            distanceFromRadius: state.distanceFromRadius,
            distanceFromTownZip: state.distanceFromZip,
            selectedPlaceLat: state.selectedPlaceLat,
            selectedPlaceLng: state.selectedPlaceLng,
            minHousingPriceFilter: state.housingPriceSliderVal[0],
            maxHousingPriceFilter: state.housingPriceSliderVal[1],
            minLatestSchoolScore: state.schoolScoreSliderVal[0],
            maxLatestSchoolScore: state.schoolScoreSliderVal[1],
            }
          })
          .then((response) => {

            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_SERVER_ERROR_MSG", '');

            if(response.data.errors || response.data.error) {

              let errorMsg = "An unknown error occured, please check search and try again.";

              if(response.data.errors) {
                errorMsg = Object.keys(response.data.errors).map( key => response.data.errors[key][0]);

                errorMsg = errorMsg.join(" ");

              }

              if(response.data.error) {
                errorMsg = response.data.error;
              }

              //Need to get the first error.
              commit("UPDATE_SERVER_ERROR_MSG", errorMsg);

            } else {
              //We should limit this to 100 entries.
              let numZipsFound = Object.keys(response.data).length;

              if(numZipsFound > 0 && numZipsFound < 100) {

                commit("UPDATE_AFFORDABLE_GREAT_SCHOOL_ZIPS", response.data);
              } else {
                commit("UPDATE_AFFORDABLE_GREAT_SCHOOL_ZIPS", []);
                commit("UPDATE_SERVER_ERROR_MSG", 'We were unable to find any areas that meet the search criteria.  Please update the filters and try again.');
              }
            }
            

          })
          .catch(function (error) {
            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
        });
      },
      performSchoolSearch({ commit, state }) {



        commit("SET_PAGE_IS_LOADING", true);
        commit("UPDATE_SEARCHING_ALL_STATES", false);

        if(state.selectedAreaSearch == 'all-states') {
          commit("UPDATE_SEARCHING_ALL_STATES", true);
        }

        const request = axios.CancelToken.source();

        commit("SET_REQUEST", request);

        commit("UPDATE_SCHOOLS_FROM_SEARCH", []);

        axios.get('/api/search/schools', {
          cancelToken: request.token,
          params: {
            selectedAreaSearch: state.selectedAreaSearch,
            filteredStates: state.filteredStates,
            distanceFromZip: state.distanceFromZip,
            distanceFromRadius: state.distanceFromRadius,
            schoolLevel: state.schoolLevel,
            schoolYear: state.schoolYear,
            orderBy: state.schoolSearchOrderBy,
            orderByDir: state.searchOrderByDir,
            }
          })
          .then((response) => {

            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_SERVER_ERROR_MSG", '');

            if(response.data.error) {
              
              commit("UPDATE_SERVER_ERROR_MSG", response.data.error);

            } else {
              //We should limit this to 100 entries.
              let numSchoolsFound = Object.keys(response.data).length;

              if(numSchoolsFound > 0 && numSchoolsFound < 100) {

                commit("UPDATE_SCHOOLS_FROM_SEARCH", response.data);
              } else {
                commit("UPDATE_SCHOOLS_FROM_SEARCH", []);
                commit("UPDATE_SERVER_ERROR_MSG", 'We were unable to find any areas that meet the search criteria.  Please update the filters and try again.');
              }
            }
            

          })
          .catch(function (error) {
            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
        });
      },
      performRisingSchoolSearch({ commit, state }) {

        commit("SET_PAGE_IS_LOADING", true);
        commit("UPDATE_SEARCHING_ALL_STATES", false);

        if(state.selectedAreaSearch == 'all-states') {
          commit("UPDATE_SEARCHING_ALL_STATES", true);
        }

        const request = axios.CancelToken.source();

        commit("SET_REQUEST", request);

        commit("UPDATE_RISING_ZIPS", []);

        axios.get('/api/search/schools', {
          cancelToken: request.token,
          params: {
            selectedAreaSearch: state.mapSearchArea,
            selectedPlaceLat: state.selectedPlaceLat,
            selectedPlaceLng: state.selectedPlaceLng,
            filteredStates: state.filteredStates,
            distanceFromRadius: state.distanceFromRadius,
            minHousingPriceFilter: state.housingPriceSliderVal[0],
            maxHousingPriceFilter: state.housingPriceSliderVal[1],
            minLatestSchoolScore: state.schoolScoreSliderVal[0],
            maxLatestSchoolScore: state.schoolScoreSliderVal[1],
            proficiencyFilter: state.studentProficiencyFilterValue,
            schoolLevels: state.schoolLevelCheckboxVals,
            orderBy: state.schoolSearchOrderBy,
            orderByDir: state.searchOrderByDir,
            }
          })
          .then((response) => {

            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_SERVER_ERROR_MSG", '');

            if(response.data.errors) {
              
              let errorMsg = Object.keys(response.data.errors).map( key => response.data.errors[key][0]);

              //Need to get the first error.
              commit("UPDATE_SERVER_ERROR_MSG", errorMsg.join(" "));

            } else {
              //We should limit this to 100 entries.
              let numSchoolsFound = Object.keys(response.data).length;

              if(numSchoolsFound > 0 && numSchoolsFound < 100) {

                commit("UPDATE_RISING_ZIPS", response.data);
              } else {
                commit("UPDATE_RISING_ZIPS", []);
                commit("UPDATE_SERVER_ERROR_MSG", 'We were unable to find any areas that meet the search criteria.  Please update the filters and try again.');
              }
            }
            

          })
          .catch(function (error) {
            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
        });
      },
      performHousingHotnessSearch({ commit, state }) {

        commit("SET_PAGE_IS_LOADING", true);
        commit("UPDATE_SEARCHING_ALL_STATES", false);

        if(state.selectedAreaSearch == 'all-states') {
          commit("UPDATE_SEARCHING_ALL_STATES", true);
        }

        const request = axios.CancelToken.source();

        commit("SET_REQUEST", request);

        commit("UPDATE_HOT_HOUSING_ZIPS", []);

        axios.get('/api/search', {
          cancelToken: request.token,
          params: {
            searchType: 'hot_housing',
            searchOrderBy: state.searchOrderBy,
            searchOrderByDir: state.searchOrderByDir,
            searchReturnType: state.searchReturnType,
            searchArea: state.mapSearchArea,
            states: state.filteredStates,
            distanceFromRadius: state.distanceFromRadius,
            distanceFromTownZip: state.distanceFromZip,
            selectedPlaceLat: state.selectedPlaceLat,
            selectedPlaceLng: state.selectedPlaceLng,
            datePeriod: state.housingDateInput,
            minHousingPriceFilter: state.housingPriceSliderVal[0],
            maxHousingPriceFilter: state.housingPriceSliderVal[1],
            }
          })
          .then((response) => {

            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_SERVER_ERROR_MSG", '');

            if(response.data.error || response.data.errors) {

              console.log("HERE");
              
              let errorMsg = "An unknown error has occured.";

              if(response.data.hasOwnProperty('error')) {
                errorMsg = response.data.error;
              }

              if(response.data.hasOwnProperty('errors')) {
                errorMsg = Object.keys(response.data.errors).map( key => response.data.errors[key][0]);

                errorMsg = errorMsg.join(' ');
              }
              

              //Need to get the first error.
              commit("UPDATE_SERVER_ERROR_MSG", errorMsg);
              commit("UPDATE_HOT_HOUSING_ZIPS", []);

            } else {
              //We should limit this to 100 entries.
              let numZipsFound = Object.keys(response.data).length;

              if(numZipsFound > 0 && numZipsFound < 100) {

                commit("UPDATE_HOT_HOUSING_ZIPS", response.data);
              } else {
                commit("UPDATE_HOT_HOUSING_ZIPS", []);
                commit("UPDATE_SERVER_ERROR_MSG", 'We were unable to find any areas that meet the search criteria.  Please update the filters and try again.');
              }
            }
            

          })
          .catch(function (error) {
            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
        });
      },
      performChangingPlacesSearch({ commit, state }) {

        commit("SET_PAGE_IS_LOADING", true);
        commit("UPDATE_SEARCHING_ALL_STATES", false);

        if(state.selectedAreaSearch == 'all-states') {
          commit("UPDATE_SEARCHING_ALL_STATES", true);
        }

        const request = axios.CancelToken.source();

        commit("SET_REQUEST", request);

        commit("UPDATE_CHANGING_PLACES_ZIPS", []);

        axios.get('/api/search/changing_places', {
          cancelToken: request.token,
          params: {
            selectedAreaSearch: state.selectedAreaSearch,
            filteredStates: state.filteredStates,
            distanceFromZip: state.distanceFromZip,
            distanceFromRadius: state.distanceFromRadius,
            minHousingPriceFilter: state.minHousingPriceFilter,
            maxHousingPriceFilter: state.maxHousingPriceFilter,
            fromTable: 'zip_code_housing_data_annual',
            fromColumn: 'avg_median_listing_price',
            fromYear: 2018,
            toTable: 'zip_code_housing_data_annual',
            toColumn: 'avg_median_listing_price',
            toYear: 2021,
            orderBy: state.changingPlacesOrderBy,
            orderByDir: state.searchOrderByDir,
            }
          })
          .then((response) => {

            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_SERVER_ERROR_MSG", '');

            if(response.data.error) {
              
              commit("UPDATE_SERVER_ERROR_MSG", response.data.error);

            } else {
              //We should limit this to 100 entries.
              let numZipsFound = Object.keys(response.data.data).length;

              if(numZipsFound > 0 && numZipsFound < 100) {
                commit("UPDATE_CHANGING_PLACES_ZIPS", response.data.data);
              } else {
                commit("UPDATE_CHANGING_PLACES_ZIPS", []);
                commit("UPDATE_SERVER_ERROR_MSG", 'We were unable to find any areas that meet the search criteria.  Please update the filters and try again.');
              }
            }
            

          })
          .catch(function (error) {
            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
        });
      },
      performHousingInventorySearch({ commit, state }) {

        commit("SET_PAGE_IS_LOADING", true);
        commit("UPDATE_SEARCHING_ALL_STATES", false);

        if(state.selectedAreaSearch == 'all-states') {
          commit("UPDATE_SEARCHING_ALL_STATES", true);
        }

        const request = axios.CancelToken.source();

        commit("SET_REQUEST", request);

        commit("UPDATE_INVENTORY_ZIPS", []);

        axios.get('/api/search', {
          cancelToken: request.token,
          params: {
            searchType: 'housing_stat',
            searchOrderBy: state.searchOrderBy,
            searchOrderByDir: state.searchOrderByDir,
            searchReturnType: state.searchReturnType,
            searchArea: state.mapSearchArea,
            states: state.filteredStates,
            distanceFromRadius: state.distanceFromRadius,
            distanceFromTownZip: state.distanceFromZip,
            selectedPlaceLat: state.selectedPlaceLat,
            selectedPlaceLng: state.selectedPlaceLng,
            datePeriod: state.housingDateInput,
            minInventory: state.minInventorySliderVal,
            minHousingPriceFilter: state.housingPriceSliderVal[0],
            maxHousingPriceFilter: state.housingPriceSliderVal[1],
            housingInventoryCompareDate: state.housingInventoryCompareDate
            }
          })
          .then((response) => {

            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_SERVER_ERROR_MSG", '');

            if(response.data.errors || response.data.error) {
              
              let errorMsg = "An unknown error has occured.";

              if(response.data.hasOwnProperty('error')) {
                errorMsg = response.data.error;
              }

              if(response.data.hasOwnProperty('errors')) {
                errorMsg = Object.keys(response.data.errors).map( key => response.data.errors[key][0]);

                errorMsg = errorMsg.join(' ');
              }

              //Need to get the first error.
              commit("UPDATE_SERVER_ERROR_MSG", errorMsg);

            } else {
              //We should limit this to 100 entries.
              let numZipsFound = Object.keys(response.data).length;

              if(numZipsFound > 0 && numZipsFound < 100) {
                commit("UPDATE_INVENTORY_ZIPS", response.data);
              } else {
                commit("UPDATE_INVENTORY_ZIPS", []);
                commit("UPDATE_SERVER_ERROR_MSG", 'We were unable to find any areas that meet the search criteria.  Please update the filters and try again.');
              }
            }
            

          })
          .catch(function (error) {
            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
        });
      },

      performSearch({ commit, state }) {

        commit("SET_PAGE_IS_LOADING", true);

        commit("UPDATE_SEARCHING_ALL_STATES", false);

        const request = axios.CancelToken.source();

        commit("SET_REQUEST", request);

        commit("UPDATE_ZIPS", []);

        let filters = state.searchFilters.reduce((obj, item) => (obj[item.filter] = item.value, obj) ,{});

        axios.get('/api/search', {
          cancelToken: request.token,
          params: {
            searchType: state.searchType,
            searchArea: state.mapSearchArea,
            searchReturnType: state.searchReturnType,
            searchOrderBy: state.searchOrderBy,
            searchOrderByDir: state.searchOrderByDir,
            distanceFromRadius: state.distanceFromRadius,
            distanceFromTownZip: state.distanceFromZip,
            selectedPlaceName: state.surveyPlaceValue, //This needs to be updated to a proper variable name.
            selectedPlaceLat: state.selectedPlaceLat,
            selectedPlaceLng: state.selectedPlaceLng,
            states: state.filteredStates,
            filters: filters
            }
          })
          .then((response) => {

            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_SERVER_ERROR_MSG", '');

            if(response.data.error) {
              
              //let errorMsg = Object.keys(response.data.errors).map( key => response.data.errors[key][0]);
              //errorMsg.join(" ")

              //Need to get the first error.
              commit("UPDATE_SERVER_ERROR_MSG", response.data.error);

            } else {
              //We should limit this to 100 entries.
              let numZipsFound = Object.keys(response.data).length;

              if(numZipsFound > 0 && numZipsFound <= 100) {
                commit("UPDATE_ZIPS", response.data);
              } else {
                commit("UPDATE_ZIPS", []);
                commit("UPDATE_SERVER_ERROR_MSG", 'We were unable to find any areas that meet the search criteria.  Please update the filters and try again.  Sometimes changing the show results by input can improve speed and number of results displayed.');
              }
            }
            

          })
          .catch(function (error) {
            commit("SET_REQUEST", false);
            commit("SET_PAGE_IS_LOADING", false);
            commit("UPDATE_SERVER_ERROR_MSG", 'Error occured!');

            if(error.response.status === 429) {
              commit("UPDATE_SERVER_ERROR_MSG", 'Ah!  You have used all of your free searches for today.  Please come back tomorrow or consider going premium.  Just $9.99 gives you unlimited site access for 12 months.');
            }
          })
          .then(function () {
            // always executed
        });
      }
    }
}