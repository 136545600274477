import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'
import appdata from './appdata'
import auth from './auth'

export default new createStore({
    plugins:[
        createPersistedState()
    ],
    modules:{
        appdata,
        auth
    }
})