export let housingStatOrderByOptions = {
    options: [
      {
          "labelText": "Total Inventory",
          "value": "total_inventory"
      },
      {
          "labelText": "Change in Inventory",
          "value": "inventory_net_change"
      },
      {
        "labelText": "Median Listing Price",
        "value": "median_listing_price"
      },
      {
          "labelText": "Change in Median Listing Price",
          "value": "median_listing_net_change"
      }
    ],
}


