require('./bootstrap')

import { createApp } from 'vue';
import router from './router';
import store from './store';
import VueGtag from "vue-gtag";

import {
   mdiClose,
   mdiMenu,
   mdiHelpCircle,
   mdiCheckCircle,
   mdiAlertCircle,
   mdiStar,
   mdiCrosshairsGps
} from '@mdi/js'


import mdiVue from 'mdi-vue/v3'


createApp({})
  .use(store)
  .use(router)
  .use(VueGtag, {
    config: { 
      id: 'G-QD721WM0K6',
    },
  }, router)
  .use(mdiVue, {
	  icons: {
		mdiClose,
    mdiMenu,
		mdiHelpCircle,
    mdiCheckCircle,
    mdiAlertCircle,
    mdiStar,
    mdiCrosshairsGps
	  }
  })
  .mount("#app");