export let placesToLiveOrderByOptions = {
    options: [
        {
            "labelText": "Median Listing Price",
            "value": "median_listing_price"
        },
        {
            "labelText": "Median Listing Price / Sq Ft",
            "value": "median_listing_price_per_square_foot"
        },
        {
            "labelText": "Income",
            "value": "avg_agi_per_return"
        },
        {
            "labelText": "School Scores",
            "value": "overall_score"
        },
        {
            "labelText": "Population",
            "value": "population"
        },
        {
            "labelText": "Population Density",
            "value": "population_density"
        },
        {
            "labelText": "Median Age",
            "value": "median_age"
        },
        {
            "labelText": "Asian Population",
            "value": "percent_asian"
        },
        {
            "labelText": "Asian Indian Population",
            "value": "percent_asian_indian"
        },
        {
            "labelText": "Black Population",
            "value": "percent_black"
        },
        {
            "labelText": "White Population",
            "value": "percent_white"
        },
        {
            "labelText": "% Owner Occupied",
            "value": "pct_owner_occupied"
        },
        {
            "labelText": "% Renter Occupied",
            "value": "pct_renter_occupied"
        },{
            "labelText": "% Peak Housing",
            "value": "median_listing_12m_peak_change"
        }
    ],
}