export let availablePopulationOptions = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "0 to 500",
            "value": "0,500"
        },
        {
            "labelText": "500 to 1,000",
            "value": "500,1000"
        },
        {
            "labelText": "1,000 to 5,000",
            "value": "1000,5000"
        },
        {
            "labelText": "5,000 to 15,000",
            "value": "5000,15000"
        },
        {
            "labelText": "15,000 to 40,000",
            "value": "15000,40000"
        },
        {
            "labelText": "40,000 to 80,000",
            "value": "40000,80000"
        },
        {
            "labelText": "80,000 to 150,000",
            "value": "80000,150000"
        },
        {
            "labelText": "over 150,000",
            "value": "150000,15000000"
        },
    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {

                let lowHousingPrice = parseFloat(optionValueArr[0]);
                let highHousingPrice = parseFloat(optionValueArr[1]);

                if(parseFloat(attrValue) > lowHousingPrice && parseFloat(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }
            }
        }

        return selectedOption;
    }

}