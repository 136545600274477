export let availablePopulationDensityOptions = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "0 to 250",
            "value": "0,250"
        },
        {
            "labelText": "250 to 500",
            "value": "250,500"
        },
        {
            "labelText": "500 to 1,000",
            "value": "500,1000"
        },
        {
            "labelText": "1,000 to 2,000",
            "value": "1000,2000"
        },
        {
            "labelText": "2,000 to 3,000",
            "value": "2000,3000"
        },
        {
            "labelText": "3,000 to 5,000",
            "value": "3000,5000"
        },
        {
            "labelText": "5,000 to 10,000",
            "value": "5000,10000"
        },
        {
            "labelText": "10,000 to 25,000",
            "value": "10000,25000"
        },
        {
            "labelText": "25,000 to 50,000",
            "value": "25000,50000"
        },
        {
            "labelText": "50,000 to 100,000",
            "value": "50000,100000"
        },
        {
            "labelText": "over 100,000",
            "value": "100000,15000000"
        },
    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {

                let lowHousingPrice = parseFloat(optionValueArr[0]);
                let highHousingPrice = parseFloat(optionValueArr[1]);

                if(parseFloat(attrValue) > lowHousingPrice && parseFloat(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }
            }
        }

        return selectedOption;
    }

}