export let priceDeltaLast12MonthOptions = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "> 20%",
            "value": "20,1000"
        },
        {
            "labelText": "> 10%",
            "value": "10, 1000"
        },
        {
            "labelText": "0 to 10%",
            "value": "0,10"
        },
        {
            "labelText": "-10% to 0",
            "value": "-10,0"
        },
        {
            "labelText": "-10% to 10%",
            "value": "-10,10"
        },
        {
            "labelText": "< -10%",
            "value": "-1000,-10"
        },
        {
            "labelText": "< -20%",
            "value": "-1000,-20"
        },
    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {

                let lowHousingPrice = parseFloat(optionValueArr[0]);
                let highHousingPrice = parseFloat(optionValueArr[1]);

                if(parseFloat(attrValue) > lowHousingPrice && parseFloat(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }
            }
        }

        return selectedOption;
    }

}