export let availableHousingPriceOptions = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "$0k to $150k",
            "value": "0,150"
        },
        {
            "labelText": "$150k to $500k",
            "value": "150,500"
        },
        {
            "labelText": "$500k to $750k",
            "value": "500,750"
        },
        {
            "labelText": "$750k to $1.5m",
            "value": "750,1500"
        },
        {
            "labelText": "$1.5m to $3m",
            "value": "1500,3000"
        },
        {
            "labelText": "Above $3m",
            "value": "3000,10000"
        },
        {
            "labelText": "$0 to $500k",
            "value": "0,500"
        }
    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {

                let lowHousingPrice = optionValueArr[0] * 1000;
                let highHousingPrice = optionValueArr[1] * 1000;

                if(parseInt(attrValue) > lowHousingPrice && parseInt(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }
            }
        }

        return selectedOption;
    }

}