export let availableZeroTo100Options = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "0 to 25",
            "value": "0,25"
        },
        {
            "labelText": "25 to 50",
            "value": "25,50"
        },
        {
            "labelText": "50 to 60",
            "value": "50,60"
        },
        {
            "labelText": "60 to 70",
            "value": "60,70"
        },
        {
            "labelText": "70 to 80",
            "value": "70,80"
        },
        {
            "labelText": "80 to 90",
            "value": "80,90"
        },
        {
            "labelText": "90 to 100",
            "value": "90,100"
        }

    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {

                let lowHousingPrice = parseFloat(optionValueArr[0]);
                let highHousingPrice = parseFloat(optionValueArr[1]);

                if(parseFloat(attrValue) > lowHousingPrice && parseFloat(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }
            }
        }

        return selectedOption;
    }

}