export let availableDiversityOptions = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "1 to 2",
            "value": "1,2"
        },
        {
            "labelText": "2 to 3",
            "value": "2,3"
        },
        {
           "labelText": "3 to 4",
            "value": "3,4"
        },
        {
           "labelText": "4 to 5",
            "value": "4,5"
        },
        {
            "labelText": "5 to 6",
            "value": "5,6"
        },
        {
            "labelText": "1 to 3",
            "value": "1,3"
        },
        {
            "labelText": "3 to 6",
            "value": "3,6"
        },
    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {
                
                let lowHousingPrice = parseFloat(optionValueArr[0]);
                let highHousingPrice = parseFloat(optionValueArr[1]);

                if(parseFloat(attrValue) > lowHousingPrice && parseFloat(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }
            }

        }

        return selectedOption;
    }

}