export let availableHousingPriceSquareFootOptions = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "$0 to $100",
            "value": "0,100"
        },
        {
            "labelText": "$100 to $200",
            "value": "100,200"
        },
        {
            "labelText": "$200 to $300",
            "value": "200,300"
        },
        {
            "labelText": "$300 to $400",
            "value": "300,400"
        },
        {
            "labelText": "$400 to $500",
            "value": "400,500"
        },
        {
            "labelText": "$500 to $600",
            "value": "500,600"
        },
        {
            "labelText": "$600 to $700",
            "value": "600,700"
        },
        {
            "labelText": "$700 to $800",
            "value": "700,800"
        },
        {
            "labelText": "$800 to $900",
            "value": "800,900"
        },
        {
            "labelText": "$900 to $1,000",
            "value": "900,1000"
        },
        {
            "labelText": "$1000+",
            "value": "1000,100000"
        },

    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {

                let lowHousingPrice = parseFloat(optionValueArr[0]);
                let highHousingPrice = parseFloat(optionValueArr[1]);

                if(parseFloat(attrValue) > lowHousingPrice && parseFloat(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }

            }
        }

        return selectedOption;
    }

}