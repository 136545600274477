export let availableAvgIncomeOptions = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "$0 to $50k",
            "value": "0,50"
        },
        {
            "labelText": "$50k to $120k",
            "value": "50,120"
        },
        {
            "labelText": "$120k to $250k",
            "value": "120,250"
        },
        {
            "labelText": "$250k to $400k",
            "value": "250,400"
        },
        {
            "labelText": "over $400k",
            "value": "400,10000"
        },
    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {
                let lowHousingPrice = parseFloat(optionValueArr[0]) * 1000;
                let highHousingPrice = parseFloat(optionValueArr[1]) * 1000;

                if(parseFloat(attrValue) > lowHousingPrice && parseFloat(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }
            }

            
        }

        return selectedOption;
    }

}