export let hotHousingOrderByOptions = {
    options: [
        {
            "labelText": "Days on Market",
            "value": "days_on_market"
        },
        {
            "labelText": "Days on Market (mom)",
            "value": "days_on_market_mom"
        }
    ],
}


