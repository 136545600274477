import axios from 'axios'
import router from '../router'

export default {
    namespaced: true,
    state:{
        authenticated:false,
        user:{}
    },
    getters:{
        authenticated(state){
            return state.authenticated
        },
        user(state){
            return state.user
        }
    },
    mutations:{
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },
        SET_USER (state, value) {
            state.user = value
        }
    },
    actions:{
        check({commit}) {
            console.log("Checking");
        },
        getUser({commit}){

            return axios.get('/api/user').then(({data})=>{

                if(data && data.id) {
                  commit('SET_USER',data)
                  commit('SET_AUTHENTICATED',true)  
                } else {
                  commit('SET_USER',{})
                  commit('SET_AUTHENTICATED',false)
                }
                
                //router.push({name: "UserHomePage"});
            }).catch(({response:{data}})=>{
                commit('SET_USER',{})
                commit('SET_AUTHENTICATED',false)
            })
        },
        logout({commit}){
          return axios.get('/api/logout').then(({data})=>{
              commit('SET_USER',{})
              commit('SET_AUTHENTICATED',false)
              router.push({name: "HomePage"});
            }).catch(({response:{data}})=>{
              commit('SET_USER',{})
              commit('SET_AUTHENTICATED',false)
              router.push({name: "HomePage"});
            })
        }
    }
}