export let availableHousingInventoryOptions = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "0 to 5",
            "value": "0,20"
        },
        {
            "labelText": "20 to 25",
            "value": "20,50"
        },
        {
            "labelText": "50 to 100",
            "value": "50,100"
        },
        {
            "labelText": "over 10",
            "value": "10,10000"
        },
        {
            "labelText": "over 20",
            "value": "20,10000"
        },
        {
            "labelText": "over 30",
            "value": "30,10000"
        },
        {
            "labelText": "over 50",
            "value": "50,10000"
        },
        {
            "labelText": "over 100",
            "value": "100,10000"
        }
    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {

                let lowHousingPrice = parseFloat(optionValueArr[0]);
                let highHousingPrice = parseFloat(optionValueArr[1]);

                if(parseFloat(attrValue) > lowHousingPrice && parseFloat(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }

            }
        }

        return selectedOption;
    }

}