export let availableMedianAgeOptions = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "< 25 yrs",
            "value": "0,25"
        },
        {
            "labelText": "25 to 40 yrs ",
            "value": "25,40"
        },
        {
            "labelText": "40 to 55 yrs",
            "value": "40,50"
        },
        {
            "labelText": "55+ yrs",
            "value": "55,110"
        }
    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {

                let lowHousingPrice = parseInt(optionValueArr[0]);
                let highHousingPrice = parseInt(optionValueArr[1]);

                if(parseInt(attrValue) > lowHousingPrice && parseInt(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }
            }
        }

        return selectedOption;
    }

}