export let availablePctChangeOptions = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "less than -100%",
            "value": "-10,-1"
        },
        {
            "labelText": "-100% to -80%",
            "value": "-1,-.8"
        },
        {
            "labelText": "-80% to -60%",
            "value": "-.8,-.6"
        },
        {
            "labelText": "-60% to -40%",
            "value": "-.6,-.4"
        },
        {
            "labelText": "-40% to -20%",
            "value": "-.4,-.2"
        },
        {
            "labelText": "-20% to 0%",
            "value": "-.2,0"
        },
        {
            "labelText": "0% to 5%",
            "value": "0,.05"
        },
        {
            "labelText": "5% to 10%",
            "value": ".05,.1"
        },
        {
            "labelText": "10% to 20%",
            "value": ".1,.2"
        },
        {
            "labelText": "20% to 30%",
            "value": ".2,.3"
        },
        {
            "labelText": "30% to 50%",
            "value": ".3,.5"
        },
        {
            "labelText": "50% to 70%",
            "value": ".5,.7"
        },
        {
            "labelText": "70% to 10%",
            "value": ".7,1"
        },
        {
            "labelText": "more than 100%",
            "value": "1,10"
        }

    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {

                let lowHousingPrice = parseFloat(optionValueArr[0]);
                let highHousingPrice = parseFloat(optionValueArr[1]);

                if(parseFloat(attrValue) > lowHousingPrice && parseFloat(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }
            }
        }

        return selectedOption;
    }

}