export let availableHousingNetChangeOptions = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "less than -$1M",
            "value": "-10000,-1000"
        },
        {
            "labelText": "-$1M to -$750k",
            "value": "-1000,-750"
        },
        {
            "labelText": "-$750k to -$500k",
            "value": "-750,-500"
        },
        {
            "labelText": "-$500k to -$250k",
            "value": "-500,-250"
        },
        {
            "labelText": "-$250k to -$125k",
            "value": "-250,-125"
        },
        {
            "labelText": "-$125k to $0",
            "value": "-125,0"
        },
        {
            "labelText": "$0 to $50k",
            "value": "0,50"
        },
        {
            "labelText": "$50k to $100k",
            "value": "50,100"
        },
        {
            "labelText": "$100k to $150k",
            "value": "100,150"
        },
        {
            "labelText": "$150k to $200k",
            "value": "150,200"
        },
        {
            "labelText": "$200k to $250k",
            "value": "200,250"
        },
        {
            "labelText": "$250k to $300k",
            "value": "250,300"
        },
        {
            "labelText": "$300k to $350k",
            "value": "300,350"
        },
        {
            "labelText": "$350k to $400k",
            "value": "350,400"
        },
        {
            "labelText": "$400k to $450k",
            "value": "400,450"
        },
        {
            "labelText": "$450k to $500k",
            "value": "450,500"
        },
        {
            "labelText": "more than $500k",
            "value": "500-10000"
        }
    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {

                let lowHousingPrice = parseInt(optionValueArr[0]) * 1000;
                let highHousingPrice = parseInt(optionValueArr[1]) * 1000;

                if(parseInt(attrValue) > lowHousingPrice && parseInt(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }
            }
        }

        return selectedOption;
    }

}