import { createWebHistory, createRouter } from "vue-router";

import store from '../store'

/* Guest Component */
const PageLayout = () => import('../components/Layouts/PageLayout.vue');

const HomePage = () => import('../components/Pages/Home.vue');

const LoginPage = () => import('../components/Pages/Login.vue');

const SignUpPage = () => import('../components/Pages/SignUp.vue');

const ContactUsPage = () => import('../components/Pages/ContactUsPage.vue');

const LookUpPage = () => import('../components/Pages/LookUpPage.vue');

const ForgotPasswordPage = () => import('../components/Pages/ForgotPasswordPage.vue');

const CalcPage = () => import('../components/Pages/Calc.vue');

const UserHomePage = () => import('../components/Pages/User/Home.vue');

const PlaceFinderPage = () => import('../components/Pages/PlaceFinderPage.vue');

const PrivacyPolicyPage = () => import('../components/Pages/PrivacyPolicy.vue');

const TermsOfServicePage = () => import('../components/Pages/TermsOfService.vue');

const DiscoverPage = () => import('../components/Pages/Discover.vue');

const MapPage = () => import(/* webpackPrefetch: true */'../components/Pages/Map.vue');

const ResearchPage = () => import('../components/Pages/Research.vue');

const DataPage = () => import('../components/Pages/Data.vue');

const FAQsPage = () => import('../components/Pages/FAQs.vue');

const AboutPage = () => import('../components/Pages/About.vue');

const BlogHomePage = () => import('../components/Pages/Blog/main.vue')

const NewsletterPage = () => import('../components/Pages/Newsletter.vue');

const PremiumSignupPage = () => import('../components/Pages/Premium');

const SingleZip = () => import('../components/Pages/Zips/Single.vue');

const SingleSchool = () => import('../components/Pages/Schools/Single.vue');

const SinglePlace = () => import('../components/Pages/Places/Single.vue');

const SchoolsRising = () => import('../components/Pages/Schools/Rising');

const MostImprovedSchoolsPage = () => import('../components/Pages/Schools/MostImproved');

const TopRatedSchoolsPage = () => import('../components/Pages/Schools/TopRated');

const SchoolDistrictsPage = () => import('../components/Pages/Schools/Districts');

const CompareZipsPage = () => import('../components/Pages/Zips/Compare.vue');

const SchoolDistrictOverview = () => import('../components/Pages/Schools/DistrictOverview');

const HotHousingMarkets = () => import('../components/Pages/Housing/HotMarkets.vue');

const HousingInventory = () => import('../components/Pages/Housing/Inventory.vue');

const ChangingPlaces = () => import('../components/Pages/Places/Changing.vue');

const AffordableGreatSchools = () => import('../components/Pages/Schools/AffordableAndGreat')

const SchoolsOverviewPage = () => import('../components/Pages/Schools/Overview');

const SingleStateSchoolsOverview = () => import('../components/Pages/Schools/StateOverview');

const SingleStateSchoolLevelOverview = () => import('../components/Pages/Schools/SingleStateSchoolLevelOverview');

const StateSchoolOverview = () => import('../components/Pages/Schools/StateSchoolOverview');

const SingleStateDemographicsOverview = () => import('../components/Pages/Demographics/Overview');

const SingleStateStatView = () => import('../components/Pages/States/StatOverview');

const SingleStateZipRankings = () => import('../components/Pages/Zips/Rankings');

const NationalZipRankings = () => import('../components/Pages/Zips/NationalRankings');

const GetStartedPage = () => import('../components/Pages/GetStarted');

const NotSureWhereToLivePage = () => import('../components/Pages/NotSureWhereToLive');

const ResearchAreaPage = () => import('../components/Pages/ResearchArea');

const SchoolRankingsPage = () => import('../components/Pages/SchoolRankings');

const RealEstateTrendsPage = () => import('../components/Pages/RealEstateTrends');

const MostImprovedSchoolsInState = () => import('../components/Pages/Schools/MostImprovedSchoolsInState')

const StyleGuide = () => import('../components/Pages/Style');

const routes = [
    {
        path:"/",
        component:PageLayout,
        children: [
            {
                name: "HomePage",
                path:"",
                component: HomePage,
                meta: { title: 'Search Away - Home' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/calc",
        component:PageLayout,
        children: [
            {
                name: "CalcPage",
                path:"",
                component:CalcPage,
                meta: { title: 'Calc Page'} // <- I would to use this one
            }
        ]
    },
    {
        path:"/get-started",       
        component:PageLayout,
        children: [
            {
                name: "GetStartedPage",
                path:"",
                component:GetStartedPage,
                meta: { title: 'Search Away - Get Started' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/not-sure-where-to-live",       
        component:PageLayout,
        children: [
            {
                name: "NotSureWhereToLivePage",
                path:"",
                component:NotSureWhereToLivePage,
                meta: { title: 'Search Away - Not Sure where to live' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/research-area",       
        component:PageLayout,
        children: [
            {
                name: "ResearchAreaPage",
                path:"",
                component:ResearchAreaPage,
                meta: { title: 'Search Away - Research Area' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/real-estate-trends",       
        component:PageLayout,
        children: [
            {
                name: "RealEstateTrendsPage",
                path:"",
                component:RealEstateTrendsPage,
                meta: { title: 'Search Away - Real Estate Trends' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/school-rankings",       
        component:PageLayout,
        children: [
            {
                name: "SchoolRankingsPage",
                path:"",
                component:SchoolRankingsPage,
                meta: { title: 'Search Away - School Rankings' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/lookup",
        component:PageLayout,
        children: [
            {
                name: "LookUpPage",
                path:"",
                component:LookUpPage,
                meta: { title: 'Look Up School or Place'} // <- I would to use this one
            }
        ]
    },
    {
        path:"/contact",
        component:PageLayout,
        children: [
            {
                name: "ContactUsPage",
                path:"",
                component:ContactUsPage,
                meta: { title: 'Contact Us'} // <- I would to use this one
            }
        ]
    },
    {
        path:"/login",
        component:PageLayout,
        children: [
            {
                name: "LoginPage",
                path:"",
                component:LoginPage,
                meta: { title: 'Login'} // <- I would to use this one
            }
        ]
    },
    {
        path:"/forgot-password",
        component:PageLayout,
        children: [
            {
                name: "ForgotPasswordPage",
                path:"",
                component:ForgotPasswordPage,
                meta: { title: 'Forgot Password'} // <- I would to use this one
            }
        ]
    },
    {
        path:"/signup",
        component:PageLayout,
        children: [
            {
                name: "SignUpPage",
                path:"",
                component:SignUpPage,
                meta: { title: 'Sign Up'} // <- I would to use this one
            }
        ]
    },
    {
        path:"/style",
        component:PageLayout,
        children: [
            {
                name: "StyleGuide",
                path:"",
                component:StyleGuide,
                meta: { title: 'Style Guide'} // <- I would to use this one
            }
        ]
    },
    {
        path:"/home",
        component:PageLayout,
        children: [
            {
                name: "UserHomePage",
                path:"",
                component:UserHomePage,
                meta: { title: 'User Home Page', requiresAuth: true} // <- I would to use this one
            }
        ]
    },
    {
        path:"/privacy-policy",       
        component:PageLayout,
        children: [
            {
                name: "PrivacyPolicyPage",
                path:"",
                component:PrivacyPolicyPage,
                meta: { title: 'Search Away - Privacy Policy' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/terms-of-service",       
        component:PageLayout,
        children: [
            {
                name: "TermsOfServicePage",
                path:"",
                component:TermsOfServicePage,
                meta: { title: 'Search Away - Terms of Service' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/finder",       
        component:PageLayout,
        children: [
            {
                name: "PlaceFinderPage",
                path:"",
                component:PlaceFinderPage,
                meta: { title: 'Search Away - Place Finder' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/compare",       
        component:PageLayout,
        children: [
            {
                name: "CompareZipsPage",
                path:"",
                component:CompareZipsPage,
                meta: { title: 'Search Away - Compare Zips' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/map",       
        component:PageLayout,
        children: [
            {
                name: "MapPage",
                path:"",
                component:MapPage,
                meta: { title: 'Search Away - Map' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/changingPlaces",       
        component:PageLayout,
        children: [
            {
                name: "ChangingPlaces",
                path:"",
                component:ChangingPlaces,
                meta: { title: 'Search Away - Changing Places' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/premium",       
        component:PageLayout,
        children: [
            {
                name: "PremiumSignupPage",
                path:"",
                component:PremiumSignupPage,
                meta: { title: 'Search Away - Premium Signup' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/newsletter",       
        component:PageLayout,
        children: [
            {
                name: "NewsletterPage",
                path:"",
                component:NewsletterPage,
                meta: { title: 'Search Away - Subscribe' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/about",       
        component:PageLayout,
        children: [
            {
                name: "AboutPage",
                path:"",
                component:AboutPage,
                meta: { title: 'Search Away - About' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/faqs",       
        component:PageLayout,
        children: [
            {
                name: "FAQsPage",
                path:"",
                component:FAQsPage,
                meta: { title: 'Search Away - FAQs' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/discover",       
        component:PageLayout,
        children: [
            {
                name: "DiscoverPage",
                path:"",
                component:DiscoverPage,
                meta: { title: 'Search Away - Discover' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/research",       
        component:PageLayout,
        children: [
            {
                name: "ResearchPage",
                path:"",
                component:ResearchPage,
                meta: { title: 'Search Away - Research' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/data",       
        component:PageLayout,
        children: [
            {
                name: "DataPage",
                path:"",
                component:DataPage,
                meta: { title: 'Search Away - Data' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/schools",       
        component:PageLayout,
        children: [
            {
                name: "SchoolsOverview",
                path:"",
                component:SchoolsOverviewPage,
                meta: { title: 'Search Away - Schools' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/most-improved-schools",       
        component:PageLayout,
        children: [
            {
                name: "MostImprovedSchools",
                path:"",
                component: MostImprovedSchoolsPage,
                meta: { title: 'Search Away - Most Improved Schools' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/top-rated-schools",       
        component:PageLayout,
        children: [
            {
                name: "TopRatedSchoolsPage",
                path:"",
                component: TopRatedSchoolsPage,
                meta: { title: 'Search Away - Top Rated Schools' } // <- I would to use this one
            }
        ]
    },

    {
        path:"/schools/districts",       
        component:PageLayout,
        children: [
            {
                name: "SchoolDistricts",
                path:"",
                component:SchoolDistrictsPage,
                meta: { title: 'Search Away - Schools' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/schools/district/:district",       
        component:PageLayout,
        children: [
            {
                name: "SchoolDistrictOverview",
                path:"",
                component:SchoolDistrictOverview,
                meta: { title: 'Search Away - School District' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/schools/:state",       
        component:PageLayout,
        children: [
            {
                name: "SingleStateSchoolsOverview",
                path:"",
                component:SingleStateSchoolsOverview,
                meta: { title: 'Search Away - Schools' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/schools/:state/:level",       
        component:PageLayout,
        children: [
            {
                name: "SingleStateSchoolLevelOverview",
                path:"",
                component:SingleStateSchoolLevelOverview,
                meta: { title: 'Search Away - State School Level Overview' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/zips/rankings",
        component: PageLayout,
        children: [
            {
                name: "NationalZipRankings",
                path:"",
                component:NationalZipRankings,
                meta: { title: 'Search Away - Zip Rankings' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/zips/rankings/:state",
        component: PageLayout,
        children: [
            {
                name: "SingleStateZipRankings",
                path:"",
                component:SingleStateZipRankings,
                meta: { title: 'Search Away - Zip Rankings' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/demographics/:state",       
        component:PageLayout,
        children: [
            {
                name: "SingleStateDemographicsOverview",
                path:"",
                component:SingleStateDemographicsOverview,
                meta: { title: 'Search Away - Demographics' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/zip/:zip",       
        component:PageLayout,
        children: [
            {
                name: "SingleZipDetails",
                path:"",
                component:SingleZip,
                meta: { title: 'Search Away - Zip Details' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/school/:ncesID",       
        component:PageLayout,
        children: [
            {
                name: "SingleSchoolDetails",
                path:"",
                component:SingleSchool,
                meta: { title: 'Search Away - School Details' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/place/:town/:state",       
        component:PageLayout,
        children: [
            {
                name: "SinglePlaceDetails",
                path:"",
                component:SinglePlace,
                meta: { title: 'Search Away - Place Details' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/state-school-rankings",       
        component:PageLayout,
        children: [
            {
                name: "StateSchoolOverview",
                path:"",
                component:StateSchoolOverview,
                meta: { title: 'Search Away - State School Rankings' } // <- I would to use this one
            }
        ]
    },
    {   
        //Careful not to use a path that already exists...  LIke /Schools/Search or /Schools/Rising.
        path:"/schoolsSearch",       
        component:PageLayout,
        children: [
            {
                name: "SchoolsRising",
                path:"",
                component:SchoolsRising,
                meta: { title: 'Search Away - Schools on the Rise' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/affordableGreatSchools",       
        component:PageLayout,
        children: [
            {
                name: "AffordableGreatSchools",
                path:"",
                component:AffordableGreatSchools,
                meta: { title: 'Search Away - Great Affordable Schools' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/discover/state/:state/:page/:stat/:year",       
        component:PageLayout,
        children: [
            {
                name: "SingleStateStatView",
                path:"",
                component:SingleStateStatView,
                meta: { title: 'Search Away' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/discover/hotHousing",       
        component:PageLayout,
        children: [
            {
                name: "HotHousingMarkets",
                path:"",
                component:HotHousingMarkets,
                meta: { title: 'Search Away - Hot Housing' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/housing/inventory",       
        component:PageLayout,
        children: [
            {
                name: "HousingInventory",
                path:"",
                component:HousingInventory,
                meta: { title: 'Search Away - Housing Inventory' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/blog",       
        component:PageLayout,
        children: [
            {
                name: "BlogHomePage",
                path:"",
                component: BlogHomePage,
                meta: { title: 'Search Away - Blog' } // <- I would to use this one
            }
        ]
    },
    {
        path:"/most-improved-:level-schools-in-:statename",       
        component:PageLayout,
        children: [
            {
                name: "MostImprovedSchoolsInState",
                path:"",
                component:MostImprovedSchoolsInState,
                meta: { title: 'Search Away - Most Improved Schools in State' } // <- I would to use this one
            }
        ]
    },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

const stripTrailingSlash = (str) => {
    return str.endsWith('/') ?
        str.slice(0, -1) :
        str;
};

router.beforeEach((to, from, next) => {

    document.title = to.meta.title;

    if(to.meta.requiresAuth) {
        if(store.getters['auth/authenticated'] == false) {
            next("/login");
        }
    }

    if(store.getters['auth/authenticated'] == true && stripTrailingSlash(to.path) == '/login') {
        next("/home");
    }

    next();
});

export default router