export let distanceToLargeAirportOptions = {
    options: [
        {
            "labelText": "Any",
            "value": "any"
        },
        {
            "labelText": "< 5 miles",
            "value": "0,5"
        },
        {
            "labelText": "< 10 miles",
            "value": "0, 10"
        },
        {
            "labelText": "< 15 miles",
            "value": "0,15"
        },
        {
            "labelText": "< 20 miles",
            "value": "0,20"
        },
        {
            "labelText": "< 25 miles",
            "value": "0,25"
        },
        {
            "labelText": "< 30 miles",
            "value": "0,30"
        },
        {
            "labelText": "< 50 miles",
            "value": "0,50"
        },
        {
            "labelText": "< 100 miles",
            "value": "0,100"
        },
        {
            "labelText": "5 to 10 miles",
            "value": "5,10"
        },
        {
            "labelText": "10 to 20 miles",
            "value": "10,20"
        },
    ],

    getOptionFromDataAttribute: function(attrValue) {

        let selectedOption = null;

        for (var i = 0, len = this.options.length; i < len; i++) {

            let thisOption = this.options[i];

            let optionValueArr = thisOption.value.split(',');

            if(optionValueArr.length == 2) {

                let lowHousingPrice = parseFloat(optionValueArr[0]);
                let highHousingPrice = parseFloat(optionValueArr[1]);

                if(parseFloat(attrValue) > lowHousingPrice && parseFloat(attrValue) <= highHousingPrice) {
                    selectedOption = thisOption;
                    break;
                }
            }
        }

        return selectedOption;
    }

}